<template>
	<div class="animated fadeIn">
		<div
			v-if="!admin.includes(this.$user.details().type)"
			class="row">
			<div class="col">
				<div class="alert alert-warning w-100 pl-1">
					<div class="row no-gutters">
						<div
							class="col-auto d-flex justify-content-center my-auto"
							style="width: 40px;">
							<i class="fas fa-info mr-1" />
						</div>
						<div class="col">
							<span>{{ translate('custom_carts_description') }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="!admin.includes(this.$user.details().type) && !canCreateCart"
			class="row mb-1">
			<div class="col">
				<div class="alert alert-danger w-100 pl-1">
					<div class="row no-gutters">
						<div
							class="col-auto d-flex justify-content-center my-auto"
							style="width: 40px;">
							<i class="fas fa-info mr-1" />
						</div>
						<div class="col">
							<span>{{ translate('your_account_is_inactive_no_link', { points: pointsToActivate }) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<b-button
					v-if="admin.includes(this.$user.details().type)"
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					v-if="$can('custom_carts', 'create')"
					:disabled="!canCreateCart"
					class="float-right mr-2"
					variant="primary"
					@click="goToGenerateCustomCart()">
					{{ translate('generate_custom_cart') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			v-if="admin.includes(this.$user.details().type)"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-sm">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{ role: distributor }"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<template v-for="(tabInfo, index) in tabs">
						<b-tab :key="index">
							<template slot="title">
								<router-link
									:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
									:to="{ name: tabInfo.name }"
									class="list-group-item btn text-left">
									{{ translate(tabInfo.translate_key) }}
								</router-link>
							</template>
							<template
								v-if="tabIndex === index"
								class="p-0">
								<router-view
									:key="routerViewKey"
									@show-products="formatProducts" />
							</template>
						</b-tab>
					</template>
				</b-tabs>
			</b-col>
		</b-row>
		<custom-cart-products-modal
			:cart-products="cartProducts"
			:cart-id="selectedCart"
			:loading="cart.data.loading"
			@close="showCustomCartProductsModal = false" />
	</div>
</template>
<script>
import DataFilter from '@/components/DataFilter';
import { SEARCH_USERS } from '@/config/endpoint';
import FiltersParams from '@/mixins/FiltersParams';
import { admin, distributor } from '@/settings/Roles';
import { Grids, CustomCarts as CustomCartsMessages } from '@/translations';
import CustomCartProductsModal from '@/components/CustomCartProductsModal';
import CustomCarts from '@/util/CustomCarts';
import EventBus from '@/util/eventBus';
import CommonMixin from './mixins/Common';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

export default {
	name: 'CustomCarts',
	messages: [Grids, CustomCartsMessages],
	components: { DataFilter, CustomCartProductsModal },
	mixins: [FiltersParams, CommonMixin],
	data() {
		return {
			alert: new this.$Alert(),
			endpoint: SEARCH_USERS.endpoint,
			admin,
			distributor,
			tabIndex: 0,
			routerViewKey: '',
			tabs: [
				{
					name: 'CustomCartsActive',
					translate_key: 'active_tab',
				},
				{
					name: 'CustomCartsUsed',
					translate_key: 'used_tab',
				},
				{
					name: 'CustomCartsExpired',
					translate_key: 'expired_tab',
				},
			],
			selectedCart: '',
			showCustomCartProductsModal: false,
			cart: new CustomCarts(),
			pointsToActivate: POINTS_TO_ACTIVATE,
		};
	},
	computed: {
		cartProducts() {
			try {
				return this.cart.data.response.data.response;
			} catch (error) {
				return {};
			}
		},
	},
	watch: {
		showCustomCartProductsModal(show) {
			EventBus.$emit(show ? 'showCustomCartProductsModal' : 'hideCustomCartProductsModal');
		},
	},
	created() {
		if (admin.includes(this.$user.details().type)) {
			this.initialConfiguration();
		} else {
			this.canSponsorCheckout();
		}
	},
	methods: {
		goToGenerateCustomCart() {
			this.$router.push({ name: 'CustomCartsGenerate' }).catch(() => {});
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
		},
		formatProducts(item) {
			this.selectedCart = item;
			this.cart.getCustomCartProducts(item);
			this.showCustomCartProductsModal = true;
		},
		initialConfiguration() {
			this.showFilters = false;
			this.dontOpenFiltersFor.push('status');
			this.getFiltersfromUrl();
		},
	},
};
</script>
